<template>
  <div id="personal">
    <p>博主目前在找工作中，如果您能给一个推荐机会将不胜感激！</p>
  </div>
</template>

<script>
  export default {
    name: "personal",
    data() {
      return {};
    },
    components: {
    },
    methods: {},
    destroyed() {
      console.log('gerenxiaohui')
    }
  };
</script>
<style lang='less'>
  #personal {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  p {
    font-size: 30px;
    text-align: center;
  }
</style>